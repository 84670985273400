@font-face {
  font-family: 'Termina Demi';
  src: local('TerminaDemi'), url('../assets/fonts/termina/TerminaDemi.otf') format('opentype');
}

@font-face {
  font-family: 'Termina Light';
  src: local('TerminaLight'), url('../assets/fonts/termina/TerminaLight.otf') format('opentype');
}

@font-face {
  font-family: 'Termina Medium';
  src: local('TerminaMedium'), url('../assets/fonts/termina/TerminaMedium.otf') format('opentype');
}

@font-face {
  font-family: 'Termina Regular';
  src: local('TerminaRegular'), url('../assets/fonts/termina/TerminaRegular.otf') format('opentype');
}
