// import CDN fonts here
@import url('https://fast.fonts.net/cssapi/edecd73d-a474-4b2c-aeab-81a207ee7f8f.css');

@import './shared-forms.module.scss';

:global {
  html {
    box-sizing: border-box;
    font-size: 10px; // reset for easy REM calculatins
  }

  body {
    background: $background;
    color: $white;
    padding: 0;
    margin: 0;
    font-family: $body-font;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button {
    cursor: pointer;
  }

  // override elements that don't inherit body styles in Chrome and Safari
  button,
  input,
  select,
  textarea {
    font-family: inherit;
  }

  // hack for transparent background when autofilled in Chrome or Safari
  // https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
  input {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      -webkit-text-fill-color: $gray-700;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  svg {
    height: auto;
    fill: currentColor;
    transition: filter 300ms linear;

    &.grayscale {
      filter: grayscale(1) brightness(1.69);
    }
  }

  a,
  .underlined-link {
    color: $link-color;
    cursor: pointer;
    transition: color 200ms linear;
    text-decoration: none;
    white-space: nowrap;

    &.bold {
      @include featuredFontOfWeight('bold');
    }

    @include hover {
      color: $link-hover-color;
    }
  }

  .underlined-link {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: currentColor;
    }
  }

  b,
  strong {
    @include featuredFontOfWeight('bold');
  }

  //
  // Typography
  //

  // Remove top margins from headings
  //
  // By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
  // margin for easier control within type scales as it avoids margin collapsing.
  h1,
  h2,
  h3,
  h4 {
    margin-top: 0;
  }

  // Ensure elements that are not headings are properly
  // displayed as block level elements.
  .h1,
  .h2,
  .h3,
  .h4 {
    display: block;
  }

  h1,
  h2,
  h3,
  h4,
  .h1,
  .h2,
  .h3,
  .h4 {
    @include featuredFontOfWeight('bold');
    margin-bottom: $headings-margin-bottom;
    letter-spacing: $headings-letter-spacing;
  }

  h1,
  .h1 {
    font-size: $h1-font-size;
    line-height: $h1-line-height;
  }

  h2,
  .h2 {
    font-size: $h2-font-size;
    line-height: $h2-line-height;
  }

  h3,
  .h3 {
    font-size: $h3-font-size;
    line-height: $h3-line-height;
  }

  h4,
  .h4 {
    font-size: $h4-font-size;
    line-height: $h4-line-height;
  }

  p {
    margin-bottom: $paragraphs-margin-bottom;
  }

  small,
  .small {
    font-size: $small-font-size;
    font-family: $featured-font;
    text-transform: uppercase;
  }

  sub,
  sup {
    font-size: 60%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5rem;
  }

  //
  // Misc
  //

  hr {
    margin-top: $hr-margin-y;
    margin-bottom: $hr-margin-y;
    border-top: $hr-border-width solid $hr-border-color;
  }

  .blockquote {
    margin-bottom: $blockquote-margin-bottom;
    font-size: $blockquote-font-size;
  }

  .blockquote-footer {
    font-size: $blockquote-footer-font-size;
  }

  .sr-only {
    @include sr-only;
  }

  .fade {
    transition: opacity 200ms ease-in-out;

    &:not(.show) {
      opacity: 0;
    }
  }

  .collapse {
    &:not(.show) {
      display: none;
    }
  }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: $transition-collapse;
  }

  .clickable {
    cursor: pointer;
  }

  .error {
    color: $danger;
  }

  .alerts-manager {
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0) !important;
    display: flex;
    flex-direction: column;
    top: 40px;
    max-width: 600px;
    width: 93vw;
    z-index: 9999999999;

    > div {
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      border-radius: 16px;
      display: flex;
      color: $white;

      svg {
        width: 20px;
        margin-right: 20px;
      }

      &.warning-alert {
        color: $black;

        svg:not(.close-alert-icon) {
          width: 60px;
        }
      }
    }
  }

  .password-input input::-ms-reveal {
    /* Remove native MS Edge password reveal via Pseudo-element targeting:
       https://docs.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal#remove-the-password-reveal-control */
    display: none;
  }

  //
  // App Globals Beyond This Point
  //
} // end :global

