$white: #fff !default;
$gray-100: #fff;
$gray-200: #f0f0f0 !default;
$gray-300: #cccccc !default;
$gray-400: #999999 !default;
$gray-500: #555555 !default;
$gray-600: #333333 !default;
$gray-700: #191919 !default;
$gray-800: #050505 !default;
$gray-900: #000 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
  ),
  $grays
);

$green-100: #a3f9d5 !default;
$green-200: #6eefc9 !default;
$green-300: #4bcbb1 !default;
$green-400: #2ca293 !default;
$green-500: #1f9081 !default;
$green-600: #117367 !default;
$green-700: #0b655c !default;
$green-800: #07544e !default;
$green-900: #013d3b !default;

$greens: () !default;
$greens: map-merge(
  (
    '100': $green-100,
    '200': $green-200,
    '300': $green-300,
    '400': $green-400,
    '500': $green-500,
    '600': $green-600,
    '700': $green-700,
    '800': $green-800,
    '900': $green-900,
  ),
  $greens
);

$primary: $green-500 !default;
$secondary: $black !default;
$success: $green-500 !default;
$money: #36862f !default;
$negative-money: #ff5c43 !default;
$warning: #ffa800 !default;
$danger: #dc3519 !default;
$light: $gray-200 !default;
$dark: $gray-700 !default;
$info: $light !default;
$tgs-disclaimer-color: #828282 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'money': $money,
    'negative-money': $negative-money,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

$onboarding-background-gradient: linear-gradient(128deg, rgba(#292929, 0.9) 28.37%, rgba(0, 0, 0, 0.00) 126.04%) !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-700 !default;
$yiq-text-light: $white !default;

$v2-black: #041d1a !default;
$v2-orange: #ff8125 !default;
$v2-pink: #f966d8 !default;
$v2-light-green: #8afba3 !default;
$v2-forest-green: #255c5a !default;
$v2-blue: #497cff !default;
$v2-red: #ff4f4f !default;

$off-black: #2b2935 !default;
$new-black: #1a1b1d !default;
$v2-grey: #98a7c3 !default;
$v2-light-grey: #eaedf3 !default;
$v2-grey-blue: #3a364b !default;

$v2-magenta: #ad2e89 !default;
$v2-moderate-blue: #504ed2 !default;
$v2-lime-green: #7eb339 !default;
$v2-light-blue: #3b7db9 !default;
$v2-burnt-orange: #b57438 !default;
$v2-green: #35aa33 !default;
$v2-gold: #b09c35 !default;
$v2-yellow: #cbbd3f !default;

$background: $black;

$font-weight-regular: 400;

$featured-font: 'Sofia W05 Regular', 'Roboto', Futura, Calibri, 'Helvetica Neue', -apple-system,
  Verdana, sans-serif !default;
$featured-font-light: 'Sofia W05 Light', 'Roboto', Futura, Calibri, 'Helvetica Neue', -apple-system,
  Verdana, sans-serif !default;
$featured-font-bold: 'Sofia W05 Bold', 'Roboto', Futura, Calibri, 'Helvetica Neue', -apple-system,
  Verdana, sans-serif !default;
$body-font: $featured-font;

$font-size-lg: 1.8rem !default;
$font-size-base: 1.6rem !default;
$font-size-sm: 1.4rem !default;
$font-size-xsm: 1.2rem !default;

$line-height-lg: 2.4 !default;
$line-height-base: 2.4 !default;
$line-height-sm: 2.4 !default;
$line-height-xsm: 2.4 !default;

$headings-margin-bottom: 1.2rem !default;
$headings-color: $white !default;
$headings-letter-spacing: 0 !default;

$h1-font-size: 5.2rem !default;
$h2-font-size: 3.6rem !default;
$h3-font-size: 2.0rem !default;
$h4-font-size: 1.6rem !default;

$h1-line-height: 6.2rem !default;
$h2-line-height: 4.3rem !default;
$h3-line-height: 2.4rem !default;
$h4-line-height: 2rem !default;

$paragraphs-margin-bottom: 1.88rem !default;

$small-font-size: 1.2rem !default;

$blockquote-font-size: 2.4rem !default;
$blockquote-footer-font-size: 1.8rem !default;
$blockquote-margin-bottom: 1.2rem !default;

@mixin letterspacing($units) {
  letter-spacing: ($units/1000 * 1rem);
}

@mixin featuredFontOfWeight($weight: 'regular') {
  font-weight: $font-weight-regular;

  @if ($weight == 'light') {
    font-family: $featured-font-light;
  } @else if ($weight == 'bold') {
    font-family: $featured-font-bold;
  } @else {
    font-family: $featured-font;
  }
}

// Easings
$ease-in-out-circ: cubic-bezier(0.27, 0.01, 0.38, 1.06);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);

// Transitions
$transition-duration: 300ms;
$transition-base: all $transition-duration $ease-in-out-circ !default;
$transition-fade: opacity 200ms ease-in-out !default;
$transition-collapse: height 350ms ease-in-out !default;

@mixin transition($transition...) {
  @if length($transition) == 0 {
    transition: $transition-base;
  } @else {
    transition: $transition;
  }
}

// Color contrast
@function color-yiq($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

  @if ($yiq >= 150) {
    @return darken($color, 65);
  } @else {
    @return #fff;
  }
}

// Links
$link-color: currentColor !default;
$link-decoration: underline !default;
$link-hover-color: $primary;
$link-hover-decoration: underline !default;

// Components
$border-width: 1px !default;
$border-radius: 24px !default;
$border-radius-lg: 48px !default;
$border-radius-sm: 12px !default;

$hr-border-color: rgba($black, 0.2) !default;
$hr-border-width: $border-width !default;
$hr-margin-y: 1.8rem !default;

$card-box-shadow: 0 0 5px 0 rgba($black, 0.15);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

// Forms
$border-color: #becad6 !default;
$input-frozen-color: #abb6bf !default;

// Body
$body-bg: $gray-200 !default;
$body-color: $gray-700 !default;

// Spacing

$spacer: 2.4rem !default; // ~ 24px

// Buttons (shared between btn and dropdown)

$btn-padding-y: 1.2rem !default;
$btn-padding-x: 2rem !default;
$btn-padding-y-lg: 1.2rem !default;
$btn-padding-x-lg: 2.8rem !default;
$btn-padding-y-sm: 0.56rem !default;
$btn-padding-x-sm: 1.6rem !default;

$header-height-mobile: 64px;
$header-height-desktop: 80px;

// Contextual backgrounds

@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color !important;

    // Fix cards with background colors.
    &.card {
      // Improve content accessibility.
      .card-title,
      .card-body,
      .card-header,
      .card-footer {
        background-color: darken($color, 10%) !important;
      }

      // Adjust card header background.
      .card-header,
      .card-footer {
        background: darken($color, 3%);
      }
    }
  }
  a#{$parent} {
    @include hover-focus {
      background-color: darken($color, 10%) !important;
    }
  }
}

@mixin bg-gradient-variant($parent, $color) {
  #{$parent} {
    background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
  }
}

$badge-color: $white !default;
$badge-font-size: 75% !default;
$badge-padding-y: 0.6rem !default;
$badge-padding-x: 0.8rem !default;
$badge-pill-border-radius: 16rem !default;
$badge-border-radius: 0.6rem !default;
$badge-pill-padding-x: $badge-padding-x !default;

@mixin base-badge {
  @include featuredFontOfWeight('bold');
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  color: $badge-color;
  border-radius: $badge-border-radius;
}

@mixin badge-variant($bg) {
  color: color-yiq($bg);
  background-color: $bg;

  &[href] {
    @include hover-focus {
      color: color-yiq($bg);
      text-decoration: none;
      background-color: darken($bg, 10%);
    }
  }

  &:hover {
    color: color-yiq($bg);
  }
}

// Grid breakpoints
// Breakpoints are defined as a map of (name: minimum width), order from small to large:
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  ml: 992px,
  lg: 1024px,
  xl: 1400px,
) !default;

// Breakpoint Validation
@mixin _assert-ascending($map, $map-name) {
  $prev-key: null;
  $prev-num: null;
  @each $key, $num in $map {
    @if $prev-num == null {
      // Do nothing
    } @else if not comparable($prev-num, $num) {
      @warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    } @else if $prev-num >= $num {
      @warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
    }
    $prev-key: $key;
    $prev-num: $num;
  }
}
@mixin _assert-starts-at-zero($map) {
  $values: map-values($map);
  $first-value: nth($values, 1);
  @if $first-value != 0 {
    @warn "First breakpoint in `$grid-breakpoints` must start at 0, but starts at #{$first-value}.";
  }
}
@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints);

// Name of the next breakpoint, or null for the last breakpoint.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next(
  $name,
  $breakpoints: $grid-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 0.02px, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash infront.
// Useful for making responsive utilities.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns a blank string)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width.
// Makes the @content apply to the given breakpoint and narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name); //breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max - 1) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max - 1) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

$caret-width: 0.3em !default;

@mixin caret-down {
  border-top: $caret-width solid;
  border-right: $caret-width solid transparent;
  border-bottom: 0;
  border-left: $caret-width solid transparent;
}

@mixin caret-up {
  border-top: 0;
  border-right: $caret-width solid transparent;
  border-bottom: $caret-width solid;
  border-left: $caret-width solid transparent;
}

@mixin caret-right {
  border-top: $caret-width solid transparent;
  border-right: 0;
  border-bottom: $caret-width solid transparent;
  border-left: $caret-width solid;
}

@mixin caret-left {
  border-top: $caret-width solid transparent;
  border-right: $caret-width solid;
  border-bottom: $caret-width solid transparent;
}

@mixin caret($direction: down) {
  &::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: $caret-width * 1.667;
    vertical-align: $caret-width * 0.41667;
    content: '';
    @if $direction == down {
      @include caret-down;
    } @else if $direction == up {
      @include caret-up;
    } @else if $direction == right {
      @include caret-right;
    }
  }

  @if $direction == left {
    &::after {
      display: none;
    }

    &::before {
      display: inline-block;
      width: 0;
      height: 0;
      margin-right: $caret-width * 1.667;
      vertical-align: $caret-width * 0.41667;
      content: '';
      @include caret-left;
    }
  }

  &:empty::after {
    margin-left: 0;
  }
}

$dropdown-bg: $white !default;
$dropdown-min-width: 16rem !default;
$dropdown-padding-y: 0.8rem !default;
$dropdown-spacer: 0 !default;
$dropdown-border-color: rgba($black, 0.05) !default;
$dropdown-border-width: $border-width !default;
$dropdown-divider-bg: $gray-200 !default;
$dropdown-border-radius: $border-radius !default;

$dropdown-box-shadow: 0 0.8rem 6.4rem rgba(0, 0, 0, 0.11), 0 10px 20px rgba(0, 0, 0, 0.05),
  0 2px 3px rgba(0, 0, 0, 0.06) !default;

$dropdown-link-color: $gray-600 !default;
$dropdown-link-hover-color: darken($gray-600, 5%) !default;
$dropdown-link-hover-bg: lighten($gray-700, 55%) !default;
$dropdown-link-active-color: $white !default;
$dropdown-link-active-bg: lighten($gray-700, 40%) !default;
$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: 0.8rem !default;
$dropdown-item-padding-x: 2rem !default;
$dropdown-item-font-size: 1.5rem !default;
$dropdown-item-transition: background-color $transition-duration $ease-in-out-circ,
  color $transition-duration $ease-in-out-circ !default;

$dropdown-header-color: $gray-600 !default;

// Small dropdowns
$dropdown-sm-padding-y: 0.4rem !default;
$dropdown-sm-font-size: 1.3008rem !default;
$dropdown-sm-box-shadow: 0 0.8rem 3.2rem rgba(0, 0, 0, 0.11), 0 3px 10px rgba(0, 0, 0, 0.05),
  0 2px 3px rgba(0, 0, 0, 0.06) !default;

$dropdown-sm-item-padding-y: 0.6rem !default;
$dropdown-sm-item-padding-x: 1.4rem !default;

$dropdown-sm-item-font-size: 1.3008rem !default;

$dropdown-sm-divider-margin-x: 0 !default;
$dropdown-sm-divider-margin-y: 0.4rem !default;

@mixin disabled-form-control {
  cursor: not-allowed !important;
  color: #c0c0c0;

  * {
    cursor: not-allowed !important;
  }

  label {
    color: #c0c0c0;
  }

  input {
    opacity: 0.5;
  }
}

@mixin form-control-hint {
  font-size: 1.1rem;
  margin: 0.5rem;
  margin-left: 1.6rem;
  color: $gray-500;
}

$form-control-min-width: 250px !default;
$form-control-padding-y: 1.5rem !default;
$form-control-padding-x: 1.5rem !default;

$form-control-font-size: 1.4rem !default;
$form-control-line-height: 1.4 !default;
$form-control-color: $gray-700 !default;

$form-control-padding-y-sm: 0.9rem !default;
$form-control-padding-x-sm: 1rem !default;
$form-control-line-height-sm: $form-control-line-height !default;

$form-control-padding-y-lg: 1.2rem !default;
$form-control-padding-x-lg: 1.2rem !default;
$form-control-line-height-lg: $form-control-line-height !default;

$form-control-bg: $white !default;
$form-control-disabled-bg: $gray-200 !default;

$form-control-border-color: $green-700 !default;
$form-control-border-width: 0 !default;
$form-control-border-radius: 12px !default;
$form-control-border-radius-lg: 12px !default;
$form-control-border-radius-sm: 12px !default;

$form-control-box-shadow: 0 0 0 1px $form-control-border-color !default;

$form-control-focus-bg: $form-control-bg !default;
$form-control-focus-border-color: $green-700 !default;
$form-control-focus-box-shadow: 0 0 0 2px $form-control-focus-border-color !default;
$form-control-focus-color: $form-control-color !default;

$form-control-hover-border-color: $green-700 !default;

$form-control-placeholder-color: $gray-500 !default;

$form-control-height-border: $form-control-border-width * 2 !default;

$form-control-height-inner: ($form-control-font-size * $form-control-line-height) +
  ($form-control-padding-y * 2) !default;
$form-control-height: $form-control-height-inner !default;

$form-control-height-inner-sm: ($font-size-sm * $form-control-line-height-sm) +
  ($form-control-padding-y-sm * 2) !default;
$form-control-height-sm: calc(
  #{$form-control-height-inner-sm} + #{$form-control-height-border}
) !default;

$form-control-height-inner-lg: ($font-size-lg * $form-control-line-height-lg) +
  ($form-control-padding-y-lg * 2) !default;
$form-control-height-lg: calc(
  #{$form-control-height-inner-lg} + #{$form-control-height-border}
) !default;

$form-control-transition: box-shadow $transition-duration $ease-in-out-circ,
  border $transition-duration $ease-in-out-circ !default;

// checkboxes and radios
$custom-control-gutter: 3.6rem !default;
$custom-control-spacer-x: 1.6rem !default;
$custom-control-indicator-checked-color: $white !default;
$custom-control-indicator-checked-bg: $primary !default;
$custom-control-indicator-active-color: $white !default;
$custom-control-indicator-size: 2.4rem !default;
$custom-control-indicator-border: $form-control-border-width solid $form-control-border-color !default;
$custom-control-indicator-active-box-shadow: none !default;
$custom-control-indicator-active-bg: lighten($primary, 35%) !default;

// Form control focus state
@mixin form-control-focus() {
  &:focus,
  &:focus-within,
  &.focus {
    color: $form-control-focus-color;
    background-color: $form-control-focus-bg;
    box-shadow: 0 0 0 2px $form-control-focus-border-color;
    outline: 0;
  }
}

@mixin form-validation-state($state, $color) {
  .form-control {
    .was-validated &:#{$state},
    &.is-#{$state} {
      box-shadow: 0 0 0 1px $color;

      &:focus {
        box-shadow: 0 0 0 2px $color;
      }
    }
  }

  // Custom control validation
  .form-checkbox,
  .form-radio {
    input {
      &:not(:checked) {
        .was-validated &:#{$state},
        &.is-#{$state} {
          ~ label::before {
            border-color: $color;
          }
        }
      }

      &:checked {
        .was-validated &:#{$state},
        &.is-#{$state} {
          ~ label::before {
            background-color: $color;
            border-color: $color;
          }
        }
      }
    }
  }
}

@mixin small-form-control {
  height: $form-control-height-sm;
  padding: $form-control-padding-y-sm $form-control-padding-x-sm;
  font-size: $font-size-sm;
  line-height: $form-control-line-height-sm;
  border-radius: $form-control-border-radius-sm;
}

@mixin large-form-control {
  height: $form-control-height-lg;
  padding: $form-control-padding-y-lg $form-control-padding-x-lg;
  font-size: $font-size-lg;
  line-height: $form-control-line-height-lg;
  border-radius: $form-control-border-radius-lg;
}

// Gradients

@mixin gradient-bg($color) {
  background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x;
}

// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-x($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
  background-repeat: repeat-x;
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-y($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(
    to bottom,
    $start-color $start-percent,
    $end-color $end-percent
  );
  background-repeat: repeat-x;
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-image: linear-gradient($deg, $start-color, $end-color);
  background-repeat: repeat-x;
}
@mixin gradient-x-three-colors(
  $start-color: #00b3ee,
  $mid-color: #7a43b6,
  $color-stop: 50%,
  $end-color: #c3325f
) {
  background-image: linear-gradient(to right, $start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
}
@mixin gradient-y-three-colors(
  $start-color: #00b3ee,
  $mid-color: #7a43b6,
  $color-stop: 50%,
  $end-color: #c3325f
) {
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
}
@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}
@mixin gradient-striped($color: rgba(255, 255, 255, 0.15), $angle: 45deg) {
  background-image: linear-gradient(
    $angle,
    $color 25%,
    transparent 25%,
    transparent 50%,
    $color 50%,
    $color 75%,
    transparent 75%,
    transparent
  );
}

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin fluid-aspect($ratio: 1 1) {
  position: relative;

  &:before {
    content: '';
    width: 100%;
    position: relative;
    display: block;
    padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
  }
}

@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@mixin hover-no-touch {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin hover {
  @include hover-no-touch {
    &:hover {
      @content;
    }
  }
}

@mixin hover-not-disabled {
  @include hover-no-touch {
    &:not([disabled]):not(.disabled):hover {
      @content;
    }
  }
}

@function strip-unit($number) {
  // This works because in CSS and SASS, X units divided by one unit will produce the number
  // without the specified units (e.g. 20cm / 1cm = 20). The RHS is necessary to obtain one unit
  // worth of the original number. Unitless numbers will pass through without issue.
  // reference: https://css-tricks.com/snippets/sass/strip-unit-function/
  @return $number / ($number * 0 + 1);
}

@function fluidCalc($minSize, $maxSize, $minViewport, $maxViewport) {
  // refernce: https://www.madebymike.com.au/writing/fluid-type-calc-examples/
  // Note that the unit must be specified for all arguments, and all arguments must be the same
  // unit (eg. px, em, rem)
  @return calc(
    #{$minSize} + (#{strip-unit($maxSize)} - #{strip-unit($minSize)}) * (100vw - #{$minViewport}) / (#{strip-unit(
            $maxViewport
          )} - #{strip-unit($minViewport)})
  );
}

// Retreive color Sass maps

@function theme-color($key: 'primary') {
  @return map-get($theme-colors, $key);
}

// Request a theme color level
@function theme-color-level($color-name: 'primary', $level: 0) {
  $color: theme-color($color-name);
  $color-base: if($level > 0, $black, $white);

  @if $level < 0 {
    // Lighter values need a quick double negative for the Sass math to work
    @return mix($color-base, $color, $level * -1 * $theme-color-interval);
  } @else {
    @return mix($color-base, $color, $level * $theme-color-interval);
  }
}

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@mixin border-top-radius($radius) {
  border-top-left-radius: $radius;
  border-top-right-radius: $radius;
}

@mixin border-right-radius($radius) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin border-bottom-radius($radius) {
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin border-left-radius($radius) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin hover {
  @include hover-no-touch {
    &:hover {
      @content;
    }
  }
}

@mixin hover-focus {
  @include hover-no-touch {
    &:hover,
    &:focus {
      @content;
    }
  }
}

@mixin hover-focus-active {
  @include hover-no-touch {
    &:hover,
    &:focus,
    &:active {
      @content;
    }
  }
}

@mixin hover-not-disabled {
  @include hover-no-touch {
    &:not([disabled]):not(.disabled):hover {
      @content;
    }
  }
}

@mixin hover-no-touch {
  @media (hover: hover) {
    @content;
  }
}

@mixin raised-shadow($opacity) {
  // The below box shadows represent the visual strengths of the designs' 0.05, 0.15, 0.25
  @if ($opacity == 5) {
    box-shadow: 0 16px 16px -13px rgba(0, 0, 0, 0.16);
  }

  @if ($opacity == 15) {
    box-shadow: 0 16px 16px -13px rgba(0, 0, 0, 0.3);
  }

  @if ($opacity == 25) {
    box-shadow: 0 16px 16px -13px rgba(0, 0, 0, 0.55);
  }
}

@mixin eyebrow($isDesktop: true) {
  &:before {
    background-color: $primary;
    content: '';
    display: block;
    left: 0;
    position: absolute;
    top: 0;

    @if ($isDesktop) {
      height: 12px;
      width: 72px;
    } @else {
      height: 6px;
      width: 36px;
    }
  }
}

@keyframes :global(spin) {
  to {
    transform: rotate(360deg);
  }
}

@mixin spinner($track-color: #777, $bar-color: #fff, $size: 20px, $thickness: 2px) {
  &:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $size;
    height: $size;
    margin-top: calc(0px - (#{$size} / 2));
    margin-left: calc(0px - (#{$size} / 2));
    border-radius: 50%;
    border: $thickness solid $track-color;
    border-top-color: $bar-color;

    :global {
      animation: spin 0.75s linear infinite;
    }
  }
}

@mixin new-image-card {
  p {
    color: $white;
    position: absolute;
    bottom: 30px;
    left: 0;
    padding-top: 18px;
    padding-left: 16px;
    border-top: 1px solid rgba($white, 0.2);
    max-width: 200px;
  }
}

@mixin image-card($card-bg-color: $white) {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 0;
  border-radius: 1.2rem;
  @include raised-shadow(5);
  color: $gray-500;
  background-color: $card-bg-color;
  margin-bottom: 0;
  width: max-content;
  max-width: 335px;
  height: 180px;
  padding: 0 16px 18px;

  h3 {
    @include featuredFontOfWeight('regular');
    color: $black;
    font-size: 1.8rem;
    line-height: 2.4rem;
    padding-left: 0;
    text-transform: capitalize;
    align-self: flex-start;
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 0;
    max-width: 330px;
    width: 100%;
  }

  .illustration {
    flex-shrink: 0;
    margin-right: 24px;
    width: 88px;
  }

  &:global(.new-card) {
    color: $black;
    flex-direction: column;
    justify-content: center;

    h3 {
      text-transform: uppercase;
    }
  }

  @include media-breakpoint-up('ml') {
    margin-right: 0;
    margin-bottom: 8px;
    height: 200px;
    width: -webkit-fill-available;
    width: -moz-available;
    max-width: none;

    h3 {
      padding-left: 0;
      font-size: 2rem;
      line-height: 3.2rem;
    }
  }
}

@mixin pin-border($color, $has-focus: false) {
  @if $has-focus {
    box-shadow: 0 0 0 2px $color;
  } @else {
    box-shadow: 0 0 0 1px $color;
  }
}

@mixin get-two-column-feature-styles($size) {
  @include media-breakpoint-up($size) {
    display: block;
    column-count: 2;

    > div {
      margin-right: 5px;
      -webkit-column-break-inside: avoid;
      break-inside: avoid-column;
    }
  }
}

@mixin toggle-container ($color, $box-shadow-color) {
  $termToggleSpacing: 18px;

  display: flex;
  align-items: center;
  justify-content: center;
  
  span {
    @include featuredFontOfWeight('light');
  
    &:first-of-type {
      margin-right: $termToggleSpacing;
    }
  
    &:last-of-type {
      margin-left: $termToggleSpacing;
    }
  
    &:global(.bold) {
      @include featuredFontOfWeight('bold');
      font-weight: bold;
    }
  }
  
  :global(.term-period-toggle) {
    padding: 0px;
  
    > label {
      margin-bottom: 0;
      padding-left: 1rem;
  
      > input:focus ~ label::before {
        box-shadow: 0 0 0 2px $box-shadow-color;
        border-color: $color;
      }
  
      > input ~ label::before {
        background: $color;
      }
    }
  }
}

@mixin select-tier-form {
  width: 100%;

  .select-tier-container {
    h1 {
      font-size: 2.8rem;
      font-family: $featured-font;
      margin-bottom: 8px;

      &.without-subtext {
        margin-bottom: 32px;
      }
    }

    p {
      text-align: center;
      font-size: 14px;
      margin-bottom: 16px;

      .fee {
        font-weight: bold;
        color: white;
      }

      &:not(.choose-text) {
        color: $gray-300;
      }

      &.choose-text {
        margin-bottom: 10px;
      }
    }

    .main-detail-container {
      .benefits-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;

        h3 {
          text-align: center;
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 16px;
        }
      }
    }
  }

  @include media-breakpoint-down('sm') {
    padding-bottom: 70px;
  }

  @include media-breakpoint-up('ml') {
    .select-tier-container {
      h1 {
        font-size: 4.8rem;
      }

      > p {
        margin: 0px auto 32px;
        max-width: 865px;
      }

      p {
        font-size: 20px;
      }

      .main-detail-container {
        margin: 0 auto 24px;
      }

      :global(.buttons-container) {
        padding-top: 24px;
      }
    }
  }

  @include media-breakpoint-down('ml') {
    .select-tier-container {
      height: 100%;

      .main-detail-container {
        height: 60vh;
        overflow: hidden scroll;
      }

      :global(.buttons-container) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20vh;
        padding: 15px 20px;
        z-index: 10;
      }
    }
  }
}

@mixin changeSvgColor($color) {
  svg {
    path {
      fill: $color;
    }
  }
}

@mixin white-card-container-heading-styles {
  .logo {
    color: $primary;
    margin-bottom: 32px;
    width: 126px;
  }

  h1 {
    color: $gray-700;
    font-size: $h2-font-size;
    line-height: $h2-line-height;
    margin-bottom: 32px;
    position: relative;

    &:not(.no-eyebrow) {
      padding-top: 24px;
      @include eyebrow();
    }
  }

  @include media-breakpoint-up('md') {
    .logo {
      margin-bottom: 64px;
      width: 145px;

      &.background-image-logo {
        margin-bottom: 40px;
      }
    }

    h1 {
      font-size: $h1-font-size;
      line-height: $h1-line-height;
    }
  }
}

@mixin white-card-container {
  background-color: $white;
  color: $gray-700;
  min-height: 100vh;
  padding: 24px;
  text-align: left;
  width: 100%;

  @include white-card-container-heading-styles;

  button.full-width-card-button,
  button[type='submit'] {
    margin-top: 40px;
    width: 100%;
  }

  .additional-button {
    margin-top: 16px;
    width: 100%;
  }

  .disclaimer-text {
    margin: 40px 0 0;
  }

  @include media-breakpoint-up('md') {
    border-radius: 24px;
    min-height: 896px;
    max-width: 774px;
    margin: 64px auto;
    padding: 64px 124px;
  }
}

@mixin primary-gradient-hover-background {
  background: linear-gradient(270deg, var(--primary-gradient-base-color) 0%, var(--primary-gradient-main-color) 63.8%, $primary 100%), linear-gradient(270deg, var(--primary-gradient-base-color) 0%, $v2-forest-green 63.8%, $primary 100%), var(--primary-gradient-base-color);
}

@mixin scroll-with-no-scrollbar {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
}

.fee {
  color: $gray-500;
  font-size: 1.2rem;
  line-height: 1.9rem;
  margin-bottom: 4px;
  display: flex;
  align-items: center;

  .tooltip-trigger {
    margin-left: 8px;
    margin-top: -2px;
    width: 16px;
    cursor: pointer;
    transition: color 150ms linear;

    &:hover {
      color: $primary;
    }
  }
}
