:root {
  --account-cards-bg-color: linear-gradient(180deg, #{$off-black} -24.37%, #{$v2-forest-green} 65.34%, #{$primary} 116.25%);
  --primary-bg-color: #{$new-black};
  --font-color: #{$white};
  --dashboard-action-btn-bg-color: #{$v2-grey-blue};
  --dashboard-action-btn-border-color: #{$v2-grey-blue};
  --darken-dashboard-action-btn-bg-color: #{darken($v2-grey-blue, 5%)};
  --dashboard-action-btn-svg-color: #{$white};
  --dashboard-mobile-total-balance-color: #{$white};
  --header-logo-color: #{$white};
  --profile-button-border-color: #{$white};
  --mobile-nav-bg-color: #{$off-black};
  --mobile-nav-hover-color: #{$white};
  --image-card-gradient-base-color: #{$off-black};
  --title-border-color: #{$off-black};
  --settings-nav-bg-color: #{$off-black};
  --settings-name-circle-color: #{$primary};
  --settings-nav-card-color: #{$v2-grey-blue};
  --settings-nav-card-subtext-hover-color: #{$v2-grey};
  --settings-nav-chevron-color: #{$white};
  --primary-gradient-base-color: #{$v2-grey-blue};
  --chevron-color: #{$white};
  --primary-gradient-main-color: #{$v2-forest-green};
  --recent-transactions-border-color: transparent;
  --slider-button-color: rgba(255, 255, 255, 0.2);
  --slider-button-color-active: #{$white};
  --slider-button-color-hover: #{$primary};
  --cta-card-bg-color: #{$off-black};
  --settings-page-content-bg-color: #{$new-black};
  --table-row-border-color: #{$off-black};
  --statement-icon-container-bg-color: #{$v2-light-grey};
  --side-nav-green-bar-color: linear-gradient(90deg, #{$off-black} -41.07%, #{$v2-forest-green} -9.96%, #{$green-500} 100%);
  --reward-container-bg-color: linear-gradient(180deg, #{$off-black} -24.37%, #{$v2-forest-green} 65.34%, #{$primary} 116.25%);
  --cashback-offers-mobile-bg-gradient: linear-gradient(180deg, rgba(26, 27, 29, 0.00) 15%, rgba(26, 27, 29, 0.85) 30%, #1a1b1d 38.44%);
}

[data-theme="light"] {
  --account-cards-bg-color: linear-gradient(180deg, rgba(246, 250, 250, 0.00) 0%, rgba(246, 250, 250, 0.01) 91.17%, rgba(31, 144, 129, 0.15) 100%);
  --primary-bg-color: #f6fafa;
  --font-color: #343c3c;
  --dashboard-action-btn-bg-color: #{$white};
  --dashboard-action-btn-border-color: #d0d5dd;
  --darken-dashboard-action-btn-bg-color: #{darken(#d0d5dd, 5%)};
  --dashboard-action-btn-svg-color: #343c3c;
  --dashboard-mobile-total-balance-color: #{$v2-black};
  --header-logo-color: #{$primary};
  --profile-button-border-color: #d0d5dd;
  --mobile-nav-bg-color: #{$white};
  --mobile-nav-hover-color: #{$primary};
  --image-card-gradient-base-color: #{$white};
  --title-border-color: #d0d5dd;
  --settings-nav-bg-color: #f6fafa;
  --settings-name-circle-color: #42c1b0;
  --settings-nav-card-color: #{$white};
  --settings-nav-card-subtext-hover-color: #{$white};
  --settings-nav-chevron-color: #343c3c;
  --primary-gradient-base-color: #{$white};
  --primary-gradient-main-color: #42c1b0;
  --chevron-color: #{$off-black};
  --recent-transactions-border-color: #d0d5dd;
  --slider-button-color: #{$v2-grey};
  --slider-button-color-active: #{$primary};
  --slider-button-color-hover: #{$off-black};
  --cta-card-bg-color: #{$white};
  --settings-page-content-bg-color: #{$white};
  --table-row-border-color: #e3e9ef;
  --statement-icon-container-bg-color: #{$white};
  --side-nav-green-bar-color: #{$primary};
  --reward-container-bg-color: #{$white};
  --cashback-offers-mobile-bg-gradient: linear-gradient(180deg, rgba(246, 250, 250, 0.00) 0.02%, #f6fafa 35.74%, #f6fafa 59.29%);
}

